<template>
    <div class="isolate bg-white">
    </div>
</template>
<script setup lang="ts">
import {storeToRefs} from "pinia";

const config = useRuntimeConfig();

const useCompanyStore = companyStore()
const {getCompanyCode} = storeToRefs(useCompanyStore)
console.log(getCompanyCode.value)
setTimeout(()=>{
    if(getCompanyCode.value.length>0){
        navigateTo('/'+getCompanyCode.value, {replace: true, external:true});
    }else{
        navigateTo('/aphelia', {replace: true, external:true});
    }
},100)

import {useOneTap, type CredentialResponse} from "vue3-google-signin";
import {navigateTo} from "#app";
import {companyStore} from "~/store/companyStore";



useServerSeoMeta({
    title: config.public.APP_NAME,
    ogTitle: config.public.APP_NAME,
    description: config.public.APP_DESCRIPTION,
    ogDescription: config.public.APP_DESCRIPTION,
    ogImage: 'https://example.com/image.png',
    robots: {
        index: "true",
        follow: "true",
        all: true,
        maxImagePreview: "large"
    },
    ogUrl: "https://aphelia.io",
    ogType: "website",
    ogSiteName: "Aphelia Event"
})

useHead({
    title: config.public.APP_NAME,
    meta: [
        {
            name: 'description',
            content: config.public.APP_DESCRIPTION
        }
    ],
})


useSchemaOrg([
    defineWebSite({
        name: config.public.APP_NAME,
        description: config.public.APP_DESCRIPTION
    }),
])


definePageMeta({
    layout: 'main',
    // middleware: ['auth']
})


const {isReady, login} = useOneTap({
    disableAutomaticPrompt: true,
    onSuccess: (response: CredentialResponse) => {
        console.log("Success:", response);
    },
    onError: () => console.error("Error with One Tap Login"),
    // options
});

</script>
